<template>
  <div />
</template>

<script>
  export default {
    name: 'CentumTheme'
  }
</script>

<style lang="scss">
  /* stylelint-disable */

  #loan-pages {
    &[data-production="true"] {
      .loan-pages {
        padding-top: rem(72px) !important;

        @include mq(medium) {
          padding-top: rem(80px) !important;
        }
      }
    }

    .loan-pages {
      .zg-hero {
        display: none !important;

        h1 {
          font-size: rem(56px) !important;
          line-height: rem(64px) !important;
        }
      }

      .zg-cross-sell-section {
        .zg-cross-sell {
          color: white;
          border-radius: unset !important;

          &.zg-cross-sell--background-beige,
          &.zg-cross-sell--background-white {
            background-color: #24385b !important;
          }

          & > .icon {
            display: none;
          }

          ul li svg {
            fill: #fff !important;
          }

          .zg-button {
            background-color: #00a7f8 !important;
            border-color: #00a7f8 !important;

            &:focus,
            &:hover {
              background: #1799c1 !important;
              border-color: #1799c1 !important;
            }
          }
        }
      }

      .zg-text-section {
        a {
          color: #da8b2d;
          border-color: #da8b2d;
          font-weight: font-weight(bold);

          &:hover {
            color: #da8b2d;
            border-color: #da8b2d;
          }
        }
      }

      .zg-logo-card-section {
        .zg-module__container__head {
          max-width: 100% !important;
        }

        p {
          text-align: center;

          a {
            color: #da8b2d;
            border-color: #da8b2d;
            font-weight: font-weight(bold);

            &:hover {
              color: #da8b2d;
              border-color: #da8b2d;
            }
          }
        }

        .zg-grid--3-columns-l {
          @include mq(medium) {
            grid-template-columns: repeat(8, 1fr) !important;
          }
        }

        .zg-logo-card {
          height: rem(50px) !important;
          background-color: transparent !important;
          padding: 0 !important;
        }
      }

      .zg-usps {
        svg {
          fill: #da8b2d !important;
        }
      }

      body {
        color: #000 !important;
      }

      .zg-hero--page-type-funnel-page,
      .zg-hero--page-type-product-page,
      .zg-hero--page-type-content-page {
        background-color: #24385b !important;
      }

      .zg-hero--page-type-funnel-page {
        &.zg-hero--with-background {
          background-color: #f2f2f2 !important;
        }
      }

      .zg-hero--page-type-product-page {
        &.zg-hero--with-background.zg-hero--with-module {
          background-color: #f2f2f2 !important;
        }
      }

      .zg-hero .funnel-page {
        background-color: #f2f2f2 !important;
      }

      .zg-statusbar {
        .status--active {
          .line.line--before {
            background-color: #24385b !important;
          }
        }

        .status--passed {
          .line {
            background-color: #24385b !important;
          }

          .label {
            .dot {
              background-color: #24385b !important;
            }
          }
        }
      }

      .zg-button {
        background-color: #1db5e4 !important;
        border-color: #1db5e4 !important;

        &:hover {
          background-color: #1799c1 !important;
          border-color: #1799c1 !important;
        }

        &--outline {
          background-color: transparent !important;

          &:hover {
            background-color: transparent !important;
          }
        }

        &--link {
          background-color: transparent !important;
          border-color: transparent !important;

          &:hover {
            color: #24385b !important;
          }
        }

        &--ghost {
          background-color: transparent !important;
          border-color: transparent !important;
        }
      }

      .zg-chip {
        border-color: #00a7f8 !important;

        &:hover {
          border-color: #00a7f8 !important;
        }

        &:focus {
          border-color: #00a7f8 !important;
        }

        &:focus:not(:focus-visible) {
          border-color: #00a7f8 !important;
        }

        &--selected {
          border-color: color(secondary-500) !important;

          &:hover {
            background-color: #00a7f8 !important;
            border-color: #00a7f8 !important;
          }
        }
      }

      .zg-radio {
        input {
          background-color: #00a7f8 !important;
          border-color: #00a7f8 !important;
        }
      }

      .zg-loader {
        .circle-animation {
          stroke: #24385b !important;
        }
      }

      .zg-section--background-beige {
        background-color: #f2f2f2 !important;
      }

      .zg-slider {
        /* Chrome */
        input::-webkit-slider-runnable-track {
          background: linear-gradient(to right, #24385b var(--input-percentage), #ccc var(--input-percentage)) !important;
        }

        input::-webkit-slider-thumb {
          background-color: #00a7f8 !important;

          &:hover {
            background-color: #00a7f8 !important;
          }
        }

        /* Firefox */
        input::-moz-range-track {
          background: linear-gradient(to right, #24385b var(--input-percentage), #ccc var(--input-percentage)) !important;
        }

        input::-moz-range-thumb {
          background-color: #00a7f8 !important;

          &:hover {
            background-color: #00a7f8 !important;
          }
        }
      }

      .zg-text-section {
        border-bottom: 1px solid #f2f2f2;

        aside {
          height: 100%;
          border-left: 1px solid #f2f2f2;
          padding-left: rem(24px);
        }
      }

      .zg-testimonial-section {
        .zg-module--beige,
        .zg-module--white {
          background-color: #f2f2f2 !important;

          .testimonial {
            background-color: #fff !important;
          }
        }

        .zg-testimonial.zg-testimonial--background-beige {
          background-color: #fff !important;

          .zg-avatar.zg-avatar--background-white {
            color: #000 !important;
            background-color: #f2f2f2 !important;
          }
        }
      }

      .zg-logo-card-section {
        .logos {
          gap: 24px !important;
        }

        .zg-logo-card {
          @include mq(medium) {
            flex-basis: calc(100% / 5 - (24px * 4 / 5)) !important;
          }

          @include mq(large) {
            flex-basis: calc(100% / 7 - (24px * 6 / 7)) !important;
          }
        }
      }
    }

    &.error-404,
    &.error-500 {
      .zg-text-section {
        display: flex;
        align-items: center;
        height: calc(100vh - 100px);
        text-align: center;

        img {
          max-width: rem(380px);
          max-height: rem(380px);
          background-color: transparent !important;
          margin: 0 auto;
        }

        h1,
        h2,
        h3 {
          margin: rem(16px) 0;
        }

        p {
          margin: 0 0 rem(16px);
        }
      }
    }
  }

  /* stylelint-enable */
</style>
